<template>
  <div class="workform">
    <h4 class="form-title">{{ opts.formName }}</h4>

    <el-form ref="formRef" :inline="true" class="form-inline">
      <el-row>
        <el-col :span="24">
          <el-form-item label="公司:" prop="companyID" class="no-print">
            <el-tree-select ref="companys" v-model="forms.companyID" :data="companys" :disabled="!startNode" clearable
              @change="forms.departmentID = ''; companyChange()" check-strictly :render-after-expand="false"
              style="min-width:180px" />
          </el-form-item>
          <el-form-item label="部门:" prop="departmentID">
            <el-tree-select ref="departments" v-model="forms.departmentID" :data="departments"
              :disabled="!startNode" check-strictly :render-after-expand="false" style="min-width:120px" />
          </el-form-item>
          <el-form-item label="渠道:" prop="platformName">
            <el-select v-model="forms.platformName" style="min-width:110px" :disabled="!startNode" filterable
              :multiple-limit="1">
              <el-option v-for="dd in dict.platforms" :key="dd.value" :label="dd.label" :value="dd.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="时间:" prop="createDate" style="float:right;">
            {{ $util.fmtDate(opts.createDate, "yyyy年MM月dd日") }}
          </el-form-item>
          <el-form-item label="单号:" style="float:right;">
            {{ opts.billCode || '' }}
          </el-form-item>
          <div style="clear: both;"></div>
        </el-col>
      </el-row>

      <table class="form-table">
        <tr style="height:0px;">
          <td style="width:35%;"></td>
          <td style="width:10%;"></td>
          <td style="width:18%;"></td>
          <td></td>
        </tr>
        <tr>
          <td class="form-label">用 途</td>
          <td class="form-label">金 额</td>
          <td class="form-label" style="text-align:right">
            <div class="print-input">款项类型/货款批次：</div>
          </td>
          <td class="form-input">
            <el-select v-model="forms.feeType" filterable :disabled="!(startNode || archiveNode)">
              <!-- <el-option v-for="dd in dict.feeTypes" :key="dd.value" :label="dd.label" :value="dd.value" /> -->
              <el-option label="银行往来款" value="银行往来款"></el-option>
            </el-select>
          </td>
        </tr>
        <tr v-for="(item, index) in forms.details" :key="index">
          <td class="form-input">
            <el-input v-model="item.name" :disabled="!startNode"></el-input>
          </td>
          <td class="form-input">
            <el-input-number v-model="item.chargeFee" :min="0" :max="10000000" @change="calcSubtotal();"
              @focus="calcSubtotal();" :disabled="!startNode" placeholder="0.00" :precision="2"
              style="width:105px;"></el-input-number>
          </td>
          <td class="form-label" style="text-align:right">
            <span v-if="index == 0">收款银行及账户：</span>
            <span v-if="index == 1">银行及账户名：</span>
            <span v-if="index == 2">收款账号：</span>
          </td>
          <td class="form-input">
            <el-select v-if="index == 0" v-model="forms.bankAlias" placeholder="内部往来单位银行及账户" :disabled="!startNode"
              clearable @change="bankChange">
              <el-option v-for="dd in dict.banks" :key="dd.value" :label="dd.label" :value="dd.value">
              </el-option>
            </el-select>
            <el-input v-if="index == 1" v-model="targetBankInfoStr" disabled></el-input>
            <el-input v-if="index == 2" v-model="forms.bankAccount" disabled></el-input>
          </td>
        </tr>
        <tr>
          <td class="form-label">合 &nbsp; &nbsp; 计：</td>
          <td class="form-label form-input form-center">￥{{ forms.subtotal || "0.00" }}</td>
          <td class="form-label"></td>
          <td class="form-input"> </td>
        </tr>
        <tr>
          <td class="form-input" colspan="4">
            <el-row :gutter="20">
              <el-col :span="3" :offset="3">金额大写：</el-col>
              <el-col :span="15">{{ bigFeeStr || "零" }}</el-col>
            </el-row>
          </td>
        </tr>
        <tr>
          <td class="form-input" colspan="4">
            <el-row :gutter="20">
              <el-col :span="4" :offset="3">领导审批：</el-col>
              <el-col :span="4">部门审批：</el-col>
              <el-col :span="4">请款人：</el-col>
              <el-col :span="4">财务审批：</el-col>
              <el-col :span="4">出纳：</el-col>
            </el-row>
          </td>
        </tr>

        <tr class="no-print">
          <td class="form-label form-input form-center" colspan="4">
            <b>额外填写项</b>
          </td>
        </tr>
        <tr class="no-print">
          <td class="form-label">备 &nbsp; &nbsp; 注：</td>
          <td class="form-input" colspan="3">
            <el-input v-model="forms.remark" :disabled="!startNode"></el-input>
          </td>
        </tr>
        <tr class="no-print">
          <td class="form-label form-input" colspan="4" style="padding-left: 8em;">
            付款账户：
            <el-select v-model="forms.payBankInfo" :disabled="!startNode" @change="payBankChange"
              style="width:180px; margin-right: 50px;">
              <el-option v-for="dd in dict.banks" :key="dd.value" :label="dd.label" :value="dd.value" />
            </el-select>
            付款时间：
            <el-date-picker v-model="forms.payDate" type="date" />
          </td>
        </tr>
        <tr class="no-print">
          <td class="form-label form-input" colspan="4" style="padding-left: 8em;">
            银行名称：
            <el-input v-model="forms.payBankName" disabled style="width:180px; margin-right: 55px;"></el-input>
            账户名称：
            <el-input v-model="forms.payCardName" disabled style="width:230px;"></el-input>
          </td>
        </tr>
      </table>
    </el-form>
  </div>
</template>

<script>
import { fetchBankList } from "@/api/fund";
export default {
  name: "bookkeep",
  components: {},
  data() {
    return {
      initOpts: {
        formType: 'WLJZ',
        formCode: 'bookkeep',
        formName: '内部往来记账单',
        flowCode: 'flow-bookkeep',
        status: 0
      },
      forms: this.formdata,
      rules: {
        // bankInfo: [
        //     { required: true, message: "请输入要报销的银行账户信息", trigger: "blur" },
        // ],
        // desc: [
        //     { required: true, message: "请输入报销的原因及备注", trigger: "blur" },
        // ],
      },
      departments: [],
      companys: [],

      dict: {
        feeTypes: [],
        platforms: [],
        banks: [],
        alias: [],
      }
    };
  },
  props: {
    userinfo: {
      type: Object,
      default: {},
    },
    opts: {
      type: Object,
      default: {}
    },
    formdata: {
      type: Object,
      default: { details: [{}, {}, {}] },
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    getInitOpts() {
      return this.initOpts;
    },
    // getInitForms() {
    //   return this.initForms;
    // },
    formCheck() {
      if (!this.forms.companyID || !this.forms.departmentID) {
        this.$message({ type: "info", message: "请选择对应的公司和部门", });
        return false;
      }

      this.forms.companyName = this.$refs.companys.getNode(this.forms.companyID).data.companyName;
      this.forms.departmentName = this.$refs.departments.getNode(this.forms.departmentID).data.departmentName;

      if (!this.forms.platformName) {
        this.$message({ type: "info", message: "请选择单据的渠道平台", });
        return false;
      }

      if (!this.forms.bankUserName) {
        this.$message({ type: "info", message: "请填写收款银行账户名称", });
        return false;
      }
      if (!this.forms.bankAccount) {
        this.$message({ type: "info", message: "请填写收款银行账户编号", });
        return false;
      }
      if (!this.forms.bankName) {
        this.$message({ type: "info", message: "请填写收款银行信息", });
        return false;
      }
      if (!this.forms.feeType) {
        this.$message({ type: "info", message: "请填写单据的款项类型信息", });
        return false;
      }

      if (!this.forms.payBankInfo || !this.forms.payBankName || !this.forms.payCardName || !this.forms.payCardNo) {
        this.$message({ type: "info", message: "请选择付款银行信息", });
        return false;
      }
      if (!this.forms.payDate) {
        this.$message({ type: "info", message: "请选择转账付款的时间", });
        return false;
      }

      // if (this.forms.payCardNo == this.forms.bankAccount) {
      //   this.$message({ type: "info", message: "收款账户与付款账户相同", });
      //   return false;
      // }

      if (this.forms.details.length == 0) {
        this.$message({ type: "info", message: "请填写至少一条单据明细", });
        return false;
      }
      let valid = false;
      this.forms.details.forEach(item => {
        if (item.name && item.chargeFee > 0) {
          valid = true;
        }
      });
      if (!valid) {
        this.$message({ type: "info", message: "请补充完善明细信息", });
        return false;
      }

      return true;
    },
    getForms() {
      if (!this.opts.formDesc || this.startNode) {
        this.opts.formDesc = '金额:' + (this.forms.subtotal || '') + ';';
        this.forms.details.forEach(item => {
          if ((item.name || '').length > 0)
            this.opts.formDesc += (item.name || '') + ';';
        });
      }
      if (this.bossNode) this.opts.formDesc = '授权时间:'
        + this.$util.fmtDate(new Date(), "yyyy-MM-dd HH:mm") + ';'
        + this.opts.formDesc;
      return this.forms;
    },
    removeItem(index) {
      this.forms.details.splice(index, 1);
      console.log(this.forms.details)
    },
    companyChange() {
      if (!this.forms.companyID) return;

      var that = this;
      this.$kaung.departments(this.forms.companyID).then((res) => {
        that.departments = res;
        if (this.forms.companyID == this.companyID) {
          this.forms.departmentID = this.userinfo.departmentID;
        }
      });
    },
    calcSubtotal() {
      this.forms.subtotal = 0;
      this.forms.details.forEach(item => {
        this.forms.subtotal += item.chargeFee || 0;
      });
      this.forms.subtotal = this.forms.subtotal.toFixed(2);
    },

    bankChange() {
      var cardInfos = this.dict.alias.filter(res => res.cardAlias == this.forms.bankAlias);
      if (cardInfos && cardInfos.length > 0) {
        this.forms.bankName = cardInfos[0].bankName;
        this.forms.bankUserName = cardInfos[0].cardName;
        this.forms.bankAccount = cardInfos[0].cardNo;
      } else {
        this.forms.bankName = '';
        this.forms.bankUserName = '';
        this.forms.bankAccount = '';
      }
    },
    payBankChange() {
      var cardInfos = this.dict.alias.filter(res => res.cardAlias == this.forms.payBankInfo);
      if (cardInfos && cardInfos.length > 0) {
        this.forms.payBankName = cardInfos[0].bankName;
        this.forms.payCardName = cardInfos[0].cardName;
        this.forms.payCardNo = cardInfos[0].cardNo;
      } else {
        this.forms.payBankName = '';
        this.forms.payCardName = '';
        this.forms.payCardNo = '';
      }
    }
  },
  created() {
    let that = this;
    if (this.opts && this.opts.flowCode)
      this.initOpts = JSON.parse(JSON.stringify(this.opts));

    this.$kaung.companys().then((res) => {
      that.companys = res;
    });

    if (!this.$route.query.id) {
      this.forms.companyID = this.userinfo.companyID;
      this.companyChange();
      this.forms.departmentID = this.userinfo.departmentID;
    } else {
      this.companyChange();
    }

    this.$kaung.dictionary("workflow", "feeType").then((res) => {
      this.dict.feeTypes = res;
    });
    this.$kaung.dictionary("workflow", "platform").then((res) => {
      this.dict.platforms = res;
    });
    this.$kaung.dictionary("workflow", "banks").then((res) => {
      this.dict.banks = res;
    });
    fetchBankList().then((res) => {
      this.dict.alias = res.data;
    });
  },
  watch: {
    formdata: function (newValue, oldValue) {
      console.log(newValue);
    },
  },
  computed: {
    startNode() {
      //草稿或者退回状态
      if (this.initOpts.status == 0 || this.initOpts.status == -10 || this.initOpts.status == '')
        return true;
      return false;
    },
    endNode() {
      if (this.initOpts.status == 50)
        return true;
      return false;
    },
    payNode() {
      if (this.initOpts.nodeName && this.initOpts.nodeName.indexOf('出纳') > -1)
        return true;
      return false;
    },
    bossNode() {
      if (this.initOpts.nodeName && (this.initOpts.nodeName.indexOf('总经理') > -1 || this.initOpts.nodeName.indexOf('董事长') > -1))
        return true;
      return false;
    },
    bigFeeStr() {
      return this.$util.toBigMoney(this.forms.subtotal || 0);
    },
    targetBankInfoStr() {
      return (this.forms.bankName || '') + '-' + (this.forms.bankUserName || '');
    }
  }

};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px;
}

.el-form--inline .el-form-item {
  margin-right: 10px;
}

:deep .el-select {
  vertical-align: initial;
}

:deep .el-input__wrapper,
:deep .el-textarea__inner,
:deep .el-select .el-input.is-focus .el-input__wrapper,
:deep .el-select:hover:not(.el-select--disabled) .el-input__wrapper {
  border: none;
  max-width: 100%;
  box-shadow: none !important;

  padding: 0px 8px;
}

:deep .el-select .el-input__inner {
  padding-top: 1px;
}

:deep .el-form-item__label {
  padding: 0;
}

:deep .el-input-number__increase,
:deep .el-input-number__decrease {
  display: none;
}

/* :deep .el-input__inner {
      text-align: left;
    } */

.workform {
  max-width: 980px;
  margin: 0px auto;
  padding: 20px 20px;
  color: #000;
  background: #fff;
}

.form-table {
  width: 100%;
}

.form-label {
  background: #fff;
  text-align: center;
  padding: 0px;
  font-size: 0.95em;
  font-weight: 500;
  vertical-align: middle;

  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.form-input {
  width: auto;
  line-height: 35px;
  font-size: 0.95em;
  border: 1px solid #ccc;
  text-align: left;
  vertical-align: middle;
}


.form-center {
  text-align: center;
}


.child-table {
  font-size: 0.9em;
}

.child-table .form-label,
.child-table .form-input {
  line-height: 30px;
  padding: 5px;
}

.el-tabs__content {
  min-height: 80vh;
}

:deep .el-input-number.is-controls-right .el-input__wrapper {
  padding: 0;
}
</style>